<template lang="pug"></template>

<script>
  import { mapGetters } from "vuex"
  import { find } from "lodash-es"
  import withWebSocket from "@/mixins/withWebSocket"
  import ToastTitle from "./ToastTitle"
  import ToastBody from "./ToastBody"

  export default {
    mixins: [withWebSocket],

    mounted() {
      this.webSocketSubscribe("PlanChannel", { received: this.showToast })
    },

    data() {
      return {
        count: 0
      }
    },

    computed: mapGetters(["translatedOtaList"]),

    methods: {
      showToast(data) {
        const h = this.$createElement
        const id = `plan-toast-${this.count++}`
        const variant = data.status === "applied" ? "success" : data.status === "processing" ? "default" : "danger"
        const ota = find(this.translatedOtaList, { id: Number(data.ota_id) })

        const vNodesTitle = [
          h(ToastTitle, {
            props: {
              data,
              ota,
              variant
            }
          })
        ]

        const vNodesContent = [
          h(ToastBody, {
            props: {
              data,
              ota,
              variant
            },
            on: {
              close: () => this.$bvToast.hide(id)
            }
          })
        ]

        this.$bvToast.toast(vNodesContent, {
          id,
          toastClass: "plan-consumer-toast",
          title: vNodesTitle,
          solid: true,
          variant,
          noAutoHide: true,
          noCloseButton: true
        })
      }
    }
  }
</script>
